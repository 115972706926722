define("ember-modifier/-private/function-based/modifier", ["exports", "@ember/debug", "@ember/modifier", "ember-modifier/-private/class/modifier", "ember-modifier/-private/function-based/modifier-manager"], function (_exports, _debug, _modifier, _modifier2, _modifierManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = modifier;
  // Provide a singleton manager for each of the options. (If we extend this to
  // many more options in the future, we can revisit, but for now this means we
  // only ever allocate two managers.)
  const EAGER_MANAGER = new _modifierManager.default({
    eager: true
  });
  const LAZY_MANAGER = new _modifierManager.default({
    eager: false
  });

  // This type exists to provide a non-user-constructible, non-subclassable
  // type representing the conceptual "instance type" of a function modifier.
  // The abstract field of type `never` prevents subclassing in userspace of
  // the value returned from `modifier()`. By extending `Modifier<S>`, any
  // augmentations of the `Modifier` type performed by tools like Glint will
  // also apply to function-based modifiers as well.

  // This is the runtime signature; it performs no inference whatsover and just
  // uses the simplest version of the invocation possible since, for the case of
  // setting it on the modifier manager, we don't *need* any of that info, and
  // the two previous overloads capture all invocations from a type perspective.
  function modifier(fn) {
    let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
      eager: true
    };
    (true && !(options !== undefined) && (0, _debug.deprecate)(`ember-modifier (for ${fn.name ?? fn} at ${new Error().stack}): creating a function-based modifier without options is deprecated and will be removed at v4.0`, options !== undefined, {
      id: 'ember-modifier.function-based-options',
      for: 'ember-modifier',
      since: {
        available: '3.2.0',
        enabled: '3.2.0'
      },
      until: '4.0.0'
    }));
    (true && !((options === null || options === void 0 ? void 0 : options.eager) === false) && (0, _debug.deprecate)(`ember-modifier (for ${fn.name ?? fn} at ${new Error().stack}): creating a function-based modifier with \`{ eager: true }\` is deprecated and will be removed at v4.0`, (options === null || options === void 0 ? void 0 : options.eager) === false, {
      id: 'ember-modifier.function-based-options',
      for: 'ember-modifier',
      since: {
        available: '3.2.0',
        enabled: '3.2.0'
      },
      until: '4.0.0'
    })); // SAFETY: the cast here is a *lie*, but it is a useful one. The actual return
    // type of `setModifierManager` today is `void`; we pretend it actually
    // returns an opaque `Modifier` type so that we can provide a result from this
    // type which is useful to TS-aware tooling (e.g. Glint).
    return (0, _modifier.setModifierManager)(() => options.eager ? EAGER_MANAGER : LAZY_MANAGER, fn);
  }

  /**
   * @internal
   */
});