
      
      function once(callback) {
        let called = false;

        return (...args) => {
          if (called) {
            return null;
          } else {
            called = true;
            return callback(...args);
          }
        };
      }

      undefined
    ;
    