define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [["en", {
    "common": {
      "api-error-messages": {
        "bad-request-error": "The data entered was not in the correct format.",
        "gateway-timeout-error": "The service is being slow. Please try again later.",
        "internal-server-error": "An error occurred, our teams are working on finding a solution. Please try again later.",
        "login-unauthorized-error": "There was an error in the email address or username/password entered.",
        "login-user-blocked-error": "Your account has reached the maximum number of failed login attempts and has been temporarily blocked. Please <a href=\"{url}\">contact us</a> to unblock it.",
        "login-user-temporary-blocked-error": "You have reached too many failed login attempts. Please try again later or <a href=\"{url}\">reset your password here</a>."
      }
    },
    "pages": {
      "login": {
        "api-error-messages": {
          "login-no-permission": "You don't have the permission to connect."
        }
      }
    }
  }], ["fr", {
    "common": {
      "api-error-messages": {
        "bad-request-error": "Les données que vous avez soumises ne sont pas au bon format.",
        "gateway-timeout-error": "Le service subit des ralentissements. Veuillez réessayer ultérieurement.",
        "internal-server-error": "Une erreur interne est survenue, nos équipes sont en train de résoudre le problème. Veuillez réessayer ultérieurement.",
        "login-unauthorized-error": "L'adresse e-mail ou l'identifiant et/ou le mot de passe saisis sont incorrects.",
        "login-user-blocked-error": "Votre compte est bloqué car vous avez effectué trop de tentatives de connexion. Pour le débloquer, <a href=\"{url}\">contactez-nous</a>.",
        "login-user-temporary-blocked-error": "Vous avez effectué trop de tentatives de connexion. Réessayez plus tard ou cliquez sur <a href=\"{url}\">mot de passe oublié</a> pour le réinitialiser."
      }
    },
    "pages": {
      "login": {
        "api-error-messages": {
          "login-no-permission": "Vous n'avez pas les droits pour vous connecter."
        }
      }
    }
  }]];
  _exports.default = _default;
});